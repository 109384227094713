import { render, staticRenderFns } from "./JServiceItem.vue?vue&type=template&id=a6d8a8aa&scoped=true&"
import script from "./JServiceItem.vue?vue&type=script&lang=js&"
export * from "./JServiceItem.vue?vue&type=script&lang=js&"
import style0 from "./JServiceItem.vue?vue&type=style&index=0&id=a6d8a8aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../ware/node-v14.18.1-win-x64/node_modules/@vue/cli-service-global/node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6d8a8aa",
  null
  
)

export default component.exports